import { AboutMeType, navLinkType } from '../types';
import { FiLayout, FiSmartphone, FiSearch, FiCpu } from 'react-icons/fi';

import { ReactComponent as ReactIcon } from '../assets/svgs/technologies/react.svg';
import { ReactComponent as ReduxIcon } from '../assets/svgs/technologies/redux.svg';
import { ReactComponent as NextIcon } from '../assets/svgs/technologies/nextjs.svg';
import { ReactComponent as TypeScriptIcon } from '../assets/svgs/technologies/typescript.svg';
import { ReactComponent as SassIcon } from '../assets/svgs/technologies/sass.svg';
import TabPanel from '../components/Tabs/TabPanel';

import MihaiImage from '../assets/images/team/mihai.jpeg';

export const NAV_LINKS: navLinkType[] = [
  {
    label: 'Home',
    to: '#home',
  },
  {
    label: 'Services',
    to: '#services',
  },
  {
    label: 'Technologies',
    to: '#technologies',
  },
  {
    label: 'Portfolio',
    to: '#portfolio',
  },
  {
    label: 'Testimonials',
    to: '#testimonials',
  },
  {
    label: 'About Us',
    to: '#aboutUs',
  },
  {
    label: 'Contact',
    to: '#contact',
  },
];

export const SERVICES_CARDS_MOCK = [
  {
    icon: <FiLayout strokeWidth="1" />,
    title: 'Front End development',
    description: 'Implement user interfaces from a provided design from Figma, Photoshop or inVision',
  },
  {
    icon: <FiSmartphone strokeWidth="1" />,
    title: '100% Responsive layout',
    description:
      'Depending on the projects needs. I pay close attention to make your website look good on any screen resolutions (Mobile, Tablet, Desktop).',
  },
  {
    icon: <FiSearch strokeWidth="1" />,
    title: 'Great SEO score',
    description: 'Make you visible on search engines is one of my priority. Everybody needs to find you easy.',
  },
  {
    icon: <FiCpu strokeWidth="1" />,
    title: 'Performance',
    description: 'Clean code, scalable architecture and using latest technologies are the keys for a good performance.',
  },
];

export const TECH_CARDS_MOCK = [
  {
    icon: <ReactIcon />,
    title: 'React',
    description: 'Over 3 years of experience using this library.',
  },
  {
    icon: <ReduxIcon />,
    title: 'Redux',
    description: 'Solid knowledge about state management. Experience with Thunk and Saga approach.',
  },
  {
    icon: <NextIcon />,
    title: 'Next.JS',
    description: 'Server side rendering for even better SEO and performance.',
  },
  {
    icon: <TypeScriptIcon />,
    title: 'TypeScript',
    description: 'Better code maintainability and avoid common JS problems.',
  },
  {
    icon: <SassIcon />,
    title: 'Sass',
    description: 'Enhanced version of the CSS. It helps us to implement your design faster.',
  },
  // {
  //   icon: <ReactIcon />,
  //   title: 'React',
  //   description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.',
  // },
];

export const ABOUT_MIHAI_MOCK: AboutMeType = {
  image: MihaiImage,
  name: 'Mihai Panciu',
  description:
    "Hi! I'm Mihai. I have over 3+ years of development experience specialized in React. Problem-solver, Proactive, Fast-learner type of person which is passionate about Web Development.",
  tabs: [
    {
      label: 'Main skills',
      content: (
        <TabPanel>
          <label>Front End</label>
          <p>React (Redux, React Routing, Bootstrap)</p>
          <p>HTML5, CSS3, SASS, Styled Components</p>
          <p>TypeScript, JavaScript, Git, HTTP</p>
          <label>Soft skills</label>
          <p>Experience with Agile (Scrum), Fast Learner, Problem Solver</p>
        </TabPanel>
      ),
    },
    {
      label: 'Experience',
      content: (
        <TabPanel>
          <label>FREELANCER | React Developer</label>
          <p>Feb 2022 - Present | Remote</p>
          <label>NESS DIGITAL ENGINEERING | Web Developer</label>
          <p>Nov 2020 - Feb 2022 | Remote</p>
          <label>VAST:VISIBILITY | Web Developer</label>
          <p>Oct 2019 - Nov 2020 | Remote</p>
          <label>ENDAVA | Full-Stack Developer Intern</label>
          <p>Jul 2018 – Sep 2018 | Iaşi, RO</p>
        </TabPanel>
      ),
    },
    {
      label: 'Education',
      content: (
        <TabPanel>
          <label>Computer Science Bachelor's degree</label>
        </TabPanel>
      ),
    },
  ],
};
