import { FC } from 'react';
import AboutMe from '../../components/AboutMe/AboutMe';
import ContactUsSection from '../../components/ContactUsSection/ContactUsSection';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Hero from '../../components/Hero/Hero';
import PortfolioSection from '../../components/PortfolioSection/PortfolioSection';
import ServicesSection from '../../components/ServicesSection/ServicesSection';
import TechnologiesSection from '../../components/TechnologiesSection/TechnologiesSection';

import TestimonialsSection from '../../components/TestimonialsSection/TestimonialsSection';
import { BasicProps } from '../../types';
import { ABOUT_MIHAI_MOCK } from '../../utils/constants';

interface HomeProps extends BasicProps {}

const Home: FC<HomeProps> = (props) => {
  const {} = props;
  return (
    <>
      <Header />
      <Hero />
      <ServicesSection />
      <TechnologiesSection />
      <PortfolioSection />
      <TestimonialsSection />
      <AboutMe profile={ABOUT_MIHAI_MOCK} />
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Home;
