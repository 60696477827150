import { useEffect, useState } from 'react';

const useMountTransition = (isMounted: boolean, delay: number) => {
  const [transitionedIn, setTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !transitionedIn) {
      timeoutId = setTimeout(() => setTransitionedIn(true), 1);
    } else if (!isMounted && transitionedIn) {
      timeoutId = setTimeout(() => setTransitionedIn(false), delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, isMounted, transitionedIn]);

  return transitionedIn;
};

export default useMountTransition;
